<!-- Right Sidebar -->
<div class="side-bar right-bar margin-top-70 width-new">
  <div class="nicescroll">
    <ul class="nav nav-tabs tabs">
      <li class="active tab">
        <a href="#taskprofile" data-toggle="tab" aria-expanded="false">
          <span class="visible-xs">
            <em class="fa fa-user"></em>
          </span>
          <em class="fa fa-angle-right fa-2x fastyle right-bar-toggle waves-effect waves-light"> </em>
          <span class="hidden-xs"> {{ work.description }} </span>
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div id="taskprofile" class="tab-pane fade in active text-algn-c lettertype">
        <div class="row">
          <div class="col-md-9">
            <div class="text-center">
              <img *ngIf="work.profileImagePath" src="{{ work.profileImagePath }}" height="60" width="60"
                class="img-responsive img-circle" alt="Work Profile Image Path">
            </div>
            <div class="text-center">
              <p *ngIf="!work.profileImagePath" class="avatar-lg" [ngClass]="[
                            work.state === 'active' ? 'active-avatar' : '',
                            work.state === 'pending' ? 'pending-avatar' : '',
                            work.state === 'finalized' ? 'closed-avatar' : '',
                            work.state === 'closed' ? 'closed-avatar' : '']" class="m-b-0"></p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="btn-group" *ngIf="work.state == 'pending'">
              <button class="delete-work"><em class="fa fa-trash" (click)="onDeleteWork(work)"></em></button>
              <button class="edit-work" (click)="onOpenEditWorkModal(work)"><em class="fa fa-edit"></em></button>
            </div>
          </div>
        </div>
        <h5 class="text-primary patient-txt-color m-t-5"
          *ngIf="(clinicCode !== undefined) || (clinicCode === undefined && !isHidePatient)">
          {{ (work.patientName ? work.patientName : '') + ' ' + (work.patientSurname ? work.patientSurname : '') }}{{
          work.patientHistoryNumber ? ' - ' + work.patientHistoryNumber : '' }}
        </h5>

        <!-- <div class="row margin-t">
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><em class="fa fa-inbox icon-margin"
                            aria-hidden="true"></em>{{
                        'resource.workId' | translate }}</div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 wid-coll text-algn-style">{{ work.guid }}
                    </div>
                </div> -->
        <div class="row margin-t">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><em class="fa fa-stethoscope icon-margin"></em>{{
            'resource.collaborator' | translate }}</div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 wid-coll text-algn-style">
            {{ (work.collaboratorDescription) ? (work.collaboratorDescription + ( work.collaboratorCode ?
            '-' : '')) : '' }} {{
            work.collaboratorCode }}</div>
        </div>

        <div class="row margin-t">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><em class="fa fa-calendar icon-margin"></em>{{
            'work.createdDate' | translate }}</div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 wid-coll text-algn-style">
            {{ work.entryDate | date: 'dd/MM/yyyy'}}</div>
        </div>

        <div class="row margin-t">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"><em class="fa fa-calendar icon-margin"></em>{{
            'resource.deliverDate' | translate }}</div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 wid-coll text-algn-style">
            {{ work.deliveryDate | date: 'dd/MM/yyyy'}}</div>
        </div>
        <hr />

        <div class="more-info">
          <div class="loop">
            <h4 class="p-b-0 m-b-0">{{'work.patient_data' | translate}}</h4>
            <p class="m-b-0">{{ (work.patientName ? work.patientName : '') + ' ' + (work.patientSurname ?
              work.patientSurname : '') }}</p>
            <p class="m-b-0 m-t-5">{{ (work.patientHistoryNumber)? work.patientHistoryNumber : '' }}</p>
          </div>
          <div class="loop">
            <h4 class="p-b-0 m-b-0">{{'work.work_description' | translate}}</h4>
            <p>{{ work.workReferenceNo ? work.workReferenceNo : '' }}</p>
            <p class="m-b-0">{{ work.description ? work.description : '' }} {{work.state ? '(' : '' }}
              <ng-container *ngIf="work.state === 'pending'">
                {{ 'resource.pending' | translate }}
              </ng-container>
              <ng-container *ngIf="work.state === 'active'">
                {{ 'resource.active' | translate }}
              </ng-container>
              <ng-container *ngIf="work.state === 'closed'">
                {{ 'resource.closed' | translate }}
              </ng-container>
              <ng-container *ngIf="work.state === 'finalized'">
                {{ 'resource.finalized' | translate }}
              </ng-container>
              {{work.state ? ')' : '' }}
            </p>
          </div>
        </div>

        <div class="row">
          <h4 class="eye-style p-b-0">
            <p> <em class="fa fa-eye me-2"></em>{{ 'resource.observations' | translate }}</p>
            <p class="m-b-0"><a data-toggle="collapse" href="#collapseExample"><i class="fa fa-angle-down"
                  aria-hidden="true"></i></a></p>
            <div id="collapseExample" class="collapse">
              <div class="card card-body right-panel-obsrv-div">
                <p class="mb-0"><span class="text-muted">{{ work.workColor }}</span></p>
                <p class="mb-0"><span class="text-muted note-content">{{ work.clinicObservation }}</span></p>
              </div>
            </div>
          </h4>
        </div>
        <hr />

        <div class="row">
          <div class="col-md-12 attch-style">
            <h4 class="eye-style p-b-0">
              <p class="m-b-10">
                {{ 'resource.attachments' | translate }} ({{ attachmentList ? attachmentList.length : 0 }})
              </p>
              <div class="row">
                <ng-container *ngFor="let item of attachmentList | slice:0:(showAllAttachments ? attachmentList.length : 4)">
                  <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 attachment-style inside-image-radius text-center">
                    <a *ngIf="isImage(item)" [href]="getFileUrl(item)" target="_blank">
                      <img title="{{ 'resource.previewAttach' | translate }}"
                        class="img-thumbnail img-responsive img-rightside" alt="Attachment" [src]="getFileUrl(item)">
                      <p class="text-center">{{ item.originalName }}</p>
                    </a>
                    <a *ngIf="isDocument(item)" [href]="getFileUrl(item)" target="_blank">
                      <img title="{{ 'resource.previewAttach' | translate }}"
                        class="img-thumbnail img-responsive img-rightside" alt="Attachment" [src]="documentThumbnails">
                      <p class="text-center">{{ item.originalName }}</p>
                    </a>
                    <a *ngIf="isVideo(item)" [href]="getFileUrl(item)" target="_blank">
                      <img title="{{ 'resource.previewAttach' | translate }}"
                        class="img-thumbnail img-responsive img-rightside" alt="Attachment"
                        [src]="getThumbnailUrl(item)">
                      <p class="text-center">{{ item.originalName }}</p>
                    </a>
                  </div>
                </ng-container>
              </div>
              <p class="m-b-0" *ngIf="attachmentList.length > 4">
                <a data-toggle="collapseAttach" (click)="toggleAttachments()">
                  <i [ngClass]="showAllAttachments ? 'fa fa-angle-up' : 'fa fa-angle-down'" aria-hidden="true"></i>
                </a>
              </p>
            </h4>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<!-- /Right-bar -->

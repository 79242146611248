import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { Work } from '../../../model/work';
import { Message } from '../../../model/message';
import { Subscription, interval } from 'rxjs';
import { MessageService } from '../../../service/message.service';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { UploadService } from '../../../service/upload.service';
import { ApiConnection } from '../../../service/apiConnection';
import { ChatService } from '../../../service/chat.service';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { SocketService } from '../../../service/sockets.service';
import { saveAs } from 'file-saver';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { map } from 'rxjs/operators';
import { Attachment } from '../../../model/attachment';
import { WorkService } from '../../../service/work.service';
import { Clinic } from 'src/app/model/clinic';
import { Lab } from 'src/app/model/lab';
import { TranslateService } from '@ngx-translate/core';
import { FileUploader } from 'ng2-file-upload';

/** Sweet alert for delete warning message */
const swalWithBootstrapButtons = swal.mixin({
  confirmButtonClass: 'btn btn-success',
  cancelButtonClass: 'btn btn-danger',
  buttonsStyling: false,
});
/** eof sweet alert */

const SCROLL_OFFSET = 10000000;
const TIME_DIFFERENCE_TIMER = 30000; // miliseconds
const MESSAGE_MERGE_TIME_OUT = 1000;
const MESSAGE_MERGE_TIME_OUT_IN_SECONDS = 1;

@Component({
  selector: 'message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit, OnDestroy {

  @ViewChild('messageStr') messageBar: ElementRef;
  @Input() hasProvidedWork: boolean;
  @Input() isOpenFromDentbox?: boolean;

  private selectedFileList: any[] = [];
  private _labList: any[] = [];
  private _clinicList: any[] = [];
  private _unSeenMessageList: Message[] = [];
  private lastMsgDate: string;
  private _mList: Message[] = [];
  private _attachmentList: Attachment[] = [];
  public messageList: Message[] = [];
  public selectedWork: Work;
  public subscriber: Subscription;
  public user_type: string;
  public user_id: string;
  public currentDate: any = new Date().toLocaleDateString();
  public uniqueDateList: string[] = [];
  public messageString: string;
  public documentPath: string;
  public docNameList: string[] = [];
  public uploader: FileUploader = new FileUploader(
    {
      url: ApiConnection.FILE_SERVER_URL + ApiConnection.FILE_VERSION,
      itemAlias: 'logoPath'
    });
  public clinicName: string;
  public labName: string;
  public currentLabName = '';
  public currentClinicName = '';
  public isHome: number;
  public preloader = true;
  public latestMessage: Message;
  public translateLabels: string[] = [];
  public timeDiff: string;
  public progress = 0;
  public final = 0;
  private timer: any;
  public latest = 0;
  public oldest = 0;
  public islatest = 1;
  public isoldest = 0;
  public documentThumbnails: string;
  public tobemerged: boolean;
  public lastMsgObject: Message;
  private openedMessageId: string;
  public userLanguage: string;
  public updatedMessage: Message;
  public attachmentSize: number;
  public attachmentExtention: string;
  public loadingURL: string;
  public imgSrc: string;
  loading = false;
  loggedUserData: { name: string, code: string };

  FILE_SERVER_URL = ApiConnection.FILE_SERVER_URL + '/';

  public sent = "sent";
  public delivered = "delivered";
  public read = "read";

  constructor(private _chatService: ChatService,
    private _messageService: MessageService, public datepipe: DatePipe,
    private _workService: WorkService,
    public sanitizer: DomSanitizer,
    private _uploadService: UploadService,
    private _socketService: SocketService,
    private toaster: ToastrService,
    private _scrollToService: ScrollToService,
    private _translateService: TranslateService) {
      this._chatService.setLabels();
  }

  ngOnInit() {
    this.subscriber = this._chatService.translateLabel.subscribe(translateLabel => {
      this.translateLabels = translateLabel;
    });
    this.loading = false;
    this.user_type = sessionStorage.getItem('1F4E6C2A587EAD3371E85EC3C08CFFCF');
    this.user_id = sessionStorage.getItem('4CDC84B1E0607D91E3C7BBDE07EECC6A');
    const userData = sessionStorage.getItem('2801636AC65D840F1735DC0833A61B69');
    if (userData) { this.loggedUserData = JSON.parse(userData); }
    this.loadingURL = '../../../../assets/images/Loading.gif';
    this.imgSrc = undefined;



    this.subscriber = this._chatService.currentMessageList.subscribe(list => {
      this.triggerScrollToOffsetOnly(SCROLL_OFFSET);
      this.messageList = list;
      this.loopAndSetMessageList(this.messageList);
      this._mList = list;
      this.latestMessage = this._mList[this._mList.length - 1];
    });

    this.documentThumbnails = this._chatService.ResourcePath.DOCUMENT_THUMBNAILS;
    // socket io
    this._socketService.getUnseenMessagesEmitter();
    this._socketService.getUnseenMessagesReciever().subscribe((list) => { this._unSeenMessageList = list; });

    this._socketService.sendMessageReciever().subscribe(async (message: Message) => {
      //this.triggerScrollToOffsetOnly(SCROLL_OFFSET);
      this.updateMessageStatus(this.delivered);
      this.updateMessageStatus(this.read);
      this.latestMessage = message;

      let time = 0;
      if (this.attachmentSize > 0 && this.attachmentSize < 10000) {
        time = 5000;
      } else if (this.attachmentSize > 9999 && this.attachmentSize < 50000) {
        time = 9000;
      } else if (this.attachmentSize > 49999 && this.attachmentSize < 100000) {
        time = 14000;
      } else if (this.attachmentSize > 99999 && this.attachmentSize < 500000) {
        time = 55000;
      } else if (this.attachmentSize > 500000) {
        time = 130000;
      }

      if (this.loading && (this.attachmentExtention == '.avi' || this.attachmentExtention == '.flv' || this.attachmentExtention == '.mpeg' || this.attachmentExtention == '.mp4' || this.attachmentExtention == '.mov')) {
        await Delay(time);
        this._unSeenMessageList.push(message);
        this.loading = false;
        this.attachmentExtention = '';
        this.attachmentSize = 0;
      } else {
        this.loading = false;
        this._unSeenMessageList.push(message);
      }

      this.imgSrc = undefined;
      this.messageList.push(message);
      this.showLatestSeven();
      //this.setMessageBarFocus();
    });

    this._socketService.updateMessageReciever().subscribe((message: Message) => {
      this.messageList.find(x => x.messageId === message.messageId).text = message.text;
    });
    this.subscriber = this._chatService.currentUniqueDateList.subscribe(list => this.uniqueDateListSubscribe(list));
    this.subscriber = this._chatService.currentSelectedWork.subscribe(item => {
      if (item) {
        this.selectedWorkSubscribe(item);
      }

    });

    this.subscriber = this._chatService.getLabs().subscribe(list => this._labList = list);
    this.subscriber = this._chatService.getClinics().subscribe(list => this._clinicList = list);
    this.subscriber = this._chatService.isHome.subscribe(isHome => this.isHome = isHome);
    this.subscriber = this._chatService.lastmsgDate.subscribe(lastmsgdate => this.lastMsgDate = lastmsgdate);

    let currentCenter: Clinic | Lab;
    if (sessionStorage.getItem('50EE60E5468D8FC43984228303D24EE9')) {
      currentCenter = JSON.parse(sessionStorage.getItem('50EE60E5468D8FC43984228303D24EE9'));
      if (this.user_type === 'clinic') {
        this.clinicName = currentCenter.name;
      } else if (this.user_type === 'lab') {
        this.labName = currentCenter.name;
      }
    }

    this.preloader = false;
    this.subscriber = this._chatService.latest.subscribe(latest => this.latest = latest);
    this.subscriber = this._chatService.oldest.subscribe(oldest => this.oldest = oldest);
    this.subscriber = this._chatService.islatest.subscribe(islatest => this.islatest = islatest);
    this.subscriber = this._chatService.isoldest.subscribe(isoldest => this.isoldest = isoldest);
    this.subscriber = this._chatService.lastMsgObj.subscribe(lastMsgObj => this.lastMsgObject = lastMsgObj);

    this.timer = interval(MESSAGE_MERGE_TIME_OUT).pipe(
      map((x) => {
        if (((Date.parse(new Date().toLocaleString()) - Date.parse(this.lastMsgDate)) / 1000) > MESSAGE_MERGE_TIME_OUT_IN_SECONDS) {

          if (this.tobemerged) {
            this._socketService.updateMessageEmitter(this.lastMsgObject);

            if (this._unSeenMessageList) {
              const list: Message[] = this.filterMessagesByWork(this._unSeenMessageList, this.selectedWork['_id']);
              this.updateUnSeenMessages(list);
            }
            this._socketService.getUnseenMessagesEmitter();

            if (this.selectedFileList !== undefined && this._attachmentList.length > 0) {
              this.uploadFiles();
            }
            this.docNameList = [];
            this._chatService.setAndPublishMessage(this.selectedWork, undefined, undefined);
            this._socketService.getWorksEmitter(this.user_id, this.user_type);
            this._socketService.getAttachmentsByWorkIdEmitter(this.selectedWork['_id']);
            this.tobemerged = false;
          }
        }

      }));
    this.timer.subscribe(res => { });

    this.timer = interval(TIME_DIFFERENCE_TIMER).pipe(
      map((x) => {
        this.messageList.forEach(message => {
          message.timeDiff = this.getchatCreateDateTimeDisplay(message);
        });
      }));
    this.timer.subscribe(res => { });

    this._translateService.onLangChange.subscribe(changes => {
      const data = window.sessionStorage.getItem('50EE60E5468D8FC43984228303D24EE9');
      const user = JSON.parse(data);
      this.messageList.forEach(m => m.timeDiff = this.getchatCreateDateTimeDisplay(m, changes.lang));
    });
    //window.addEventListener('message', this.receiveMessage.bind(this), false);
    window.addEventListener('message', (event: MessageEvent) => {
      if(event.data && event.data.language){
        this.changeLanguage(event.data.language);
      }
    }, false);
  }

  public async changeLanguage(language: string): Promise<void> {
    if (language) {
      sessionStorage.setItem('currentLang', language); // save language in session storage
      this._translateService.use(language);
      
    }
    this._chatService.setLabels();
  }

  private loopAndSetMessageList(list: Message[]): void {
    this.messageList.forEach(element => {
      element.attachments.forEach(item => {
        const officeUrl = 'https://view.officeapps.live.com/op/embed.aspx?src=';
        if (item.extension === '.docx' || item.extension === '.xlsx' || item.extension === '.xls' || item.extension === '.doc') {
          let url = officeUrl + ApiConnection.FILE_SERVER_URL + '/' + element.workId + '/' + item.url;
          item.safeUrl = this.getTrustedUrl(url);
        }
        else {
          item.safeUrl = this.getTrustedUrl(ApiConnection.FILE_SERVER_URL + '/' + element.workId + '/' + item.url);
        }
      });
    });
  }

  private getTrustedUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  uniqueDateListSubscribe(list) {
    this.uniqueDateList = list;
    this.uniqueDateList.forEach(item => {
      item = this.getConvertedDate(item);
    });
    this.messageList.forEach(message => {
      message.creationDate = this.getConvertedDate(message.creationDateTime);
      message.isUnseen = this._unSeenMessageList.filter((x, i, a) => x['_id'] === message['_id']).length > 0;
      message.timeDiff = this.getchatCreateDateTimeDisplay(message); //DBX-171
      message.readDate = this.changeDateFormat(message.readDateTime);
      message.readTime = this.changeTimeFormat(message.readDateTime);
    });
  }

  selectedWorkSubscribe(item: Work): void {
    if (item && item['_id'] != null) {
      this.selectedWork = new Work();
      this.selectedWork = Object.assign(this.selectedWork, item);
      this.currentClinicName = item.clinicName;
      this.currentLabName = item.labName;
    }
  }

  ngOnDestroy() {
    clearInterval(this.timer);
    this.subscriber.unsubscribe();
  }

  public triggerScrollToOffsetOnly(offset: number = 0) {
    const config: ScrollToConfigOptions = {
      offset
    };
    this._scrollToService.scrollTo(config);
    this.setMessageBarFocus();
  }

  public setMessageBarFocus() {
    if (this.messageBar) {
      this.messageBar.nativeElement.focus();
    }
  }

  // update un seen messages
  private async updateUnSeenMessages(list: any): Promise<void> {
    if (list) {
      list.forEach(element => {
        element.readDateTime = new Date().toISOString();
        element.state = this.read;
        this._messageService.updateMessageById(element._id, element).subscribe(
          data => {
            const index: number = list.findIndex(e => e === element);
            if (index !== -1) {
              list.splice(index, 1);
            }
          },
          err => console.error(err),
          () => { });
      });
    }
  }

  // update deliveredn messages
  private async updateMessageStatus(status: string): Promise<void> {
    const dateTime = new Date().toISOString();
    this._messageService.updateMessageStatus(this.user_id, dateTime, status, this.selectedWork != null ? this.selectedWork['_id'] : null).subscribe(
      data => {
        this._socketService.getUnseenMessagesEmitter();
        this._socketService.syncMessageStatusEmitter(this.selectedWork != null ? this.selectedWork['_id'] : null);
      },
      err => console.error(err),
      () => { });
  }

  // check un seen messages in message list
  public checkInUnSeenMessageList(current_message: Message) {
    if (current_message) {
      return this._unSeenMessageList.filter((x, i, a) => x['_id'] === current_message['_id']).length > 0;
    }
    return false;
  }

  // get unseen message list
  public getUnSeenMessageList(list: any[]) {
    if (list) {
      if (this.user_type) {
        return (this.user_type === 'clinic') ? list.filter((x, i, a) => x.readDateTime === '' && x.owner === 'lab') :
          list.filter((x, i, a) => x.readDateTime === '' && x.owner === 'clinic');
      }
    }
    return [];
  }
  /* Eof Un Seen Messages */

  /* Compose / Delete Messages */
  async sendReply(): Promise<void> {
    if ((this.selectedFileList === undefined || this.selectedFileList.length === 0) &&
      (this.messageString === undefined || this.messageString === '')) {
      return;
    }

    const datediffinSeconds: number = (Date.parse(new Date().toLocaleString()) - Date.parse(this.lastMsgDate)) / 1000;
    this._chatService.setLastMsgDate();
    this.tobemerged = ((datediffinSeconds < this._chatService.MessageMergeTime) ? true : false);

    try {
      const message: Message = await this.setNewMessageData();
      if (this.tobemerged) {
        this.latestMessage = message;
        this.messageString = '';
        this.messageList.pop();
        this.messageList.push(message);
        this._chatService.setLastMsgObject(message);
      } else {
        if (this.selectedFileList !== undefined) {
          if (!this.uploadFiles()) {
            message.attachments = null;
          }
        }

        if (message.attachments.length == 0) {
          if (message.text.length > 0) {
            this.messageList.push(message);
            this._socketService.sendMessageEmitter(message);
          }
        } else {
          this.loading = true;
          let size = 0;
          for (let i = 0; i < message.attachments.length; i++) {
            size += message.attachments[i].size / 1000;
          }
          if (this.attachmentExtention == '.avi' || this.attachmentExtention == '.flv' || this.attachmentExtention == '.mpeg'|| this.attachmentExtention == '.mp4' || this.attachmentExtention == '.mov') {
            this.attachmentSize = size;
          }
          this.updatedMessage = message;
          this.messageList.push(this.updatedMessage);
          this._socketService.sendMessageEmitter(this.updatedMessage);
        }

        if (this._unSeenMessageList) {
          const list: Message[] = this.filterMessagesByWork(this._unSeenMessageList, this.selectedWork['_id']);
          this.updateUnSeenMessages(list);
        }
        this._socketService.getUnseenMessagesEmitter();

        this.messageString = '';
        this.docNameList = [];
        await this._chatService.setAndPublishMessage(this.selectedWork, undefined, undefined);
        this._socketService.getWorksEmitter(this.user_id, this.user_type);
        this._chatService.uploadAttachment.emit(this.selectedWork['_id']);
        this._socketService.getAttachmentsByWorkIdEmitter(this.selectedWork['_id']);
      }
    } catch (ex) {  }
  }

  private filterMessagesByWork(list: Message[], workId: string): Message[] {
    if (list && workId) {
      return list.filter((x, i, a) => x.workId === workId && x.recieverId === this.user_id && x.owner !== this.user_type);
    }
    return undefined;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async setNewMessageData(): Promise<Message> {
    let message = new Message();
    if (this.tobemerged) {

      if (this.latestMessage) {
        if (this.latestMessage.owner === this.user_type) {
          message = this.latestMessage;
        }
      }
      const Break = '\n';
      if (message !== undefined) {
        message.text = `` + message.text + `${Break}` + `` + ((this.messageString === undefined) ? `` : this.messageString) + ``;
        this._attachmentList.forEach(element => {
          message.attachments.push(element);
        });
      } else {
        console.log('message is undefined!');
      }
    } else {
      message.text = ((this.messageString === undefined) ? '' : this.messageString);
      message.attachments = this._attachmentList;
      message.workId = this.selectedWork['_id'];
    }

    if (message !== undefined) {
      message.readDateTime = '';
      message.deliveredDateTime = '';
      message.state = this.sent;

      switch (this.user_type) {
        case 'clinic':
          message.owner = 'clinic';
          message.senderId = this.selectedWork.clinicId;
          message.recieverId = this.selectedWork.labId;
          break;
        case 'lab':
          message.owner = 'lab';
          message.senderId = this.selectedWork.labId;
          message.recieverId = this.selectedWork.clinicId;
          break;
        default:
          console.log('no user provided!');
          message.owner = 'clinic';
          break;

      }
    }
    //this.triggerScrollToOffsetOnly(SCROLL_OFFSET);
    //this.setMessageBarFocus();

    if (this.loggedUserData) {
      message.loggedUserName = this.loggedUserData.name;
      message.loggedUserCode = this.loggedUserData.code;
    }
    return message;
  }

  async deleteMessage(id): Promise<void> {
    this._socketService.deleteMessageEmitter(id);
    const index: number = this.messageList.findIndex(e => e['_id'] === id);
    if (index !== -1) {
      this.messageList.splice(index, 1);
    }
    this._socketService.deleteMessageReciever().subscribe((result: any) => {
      this._mList = result;
      this._chatService.setAndPublishMessage(this.selectedWork, undefined, undefined);
    });

  }

  /* Eof Compose / Delete Messages */

  /* Attachments */
  public removeAttachment(index, message) {
    message.attachments.splice(index, 1);
    this._messageService.updateMessageById(message._id, message).subscribe(
      data => {
        this._chatService.setAndPublishMessage(this.selectedWork, undefined, undefined);
      },
      err => console.error(err),
      () => { });
  }

  // add attached file to array
  public onFileChange(event) {
    let toupload = true;

    this.selectedFileList.forEach(element => {
      if (event.target.files[0] && element[0]) {
        if (element[0].name === event.target.files[0].name) {
          toupload = false;
          this.toaster.error(this.translateLabels['alreadyAdded']);
        }
      }
    });
    if (toupload && event.target.files.length > 0) {
      this.selectedFileList.push(event.target.files);
      this.showDocList(event);
    }
  }

  clickFileInput(event) {
    event.target.value = '';
  }

  // show attached file names
  public showDocList(e) {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    for (let i = 0; i < target.files.length; i++) {
      this.upload(target.files[i]);
    }
  }

  // show attached file names
  public upload(file: File) {
    const formData: FormData = new FormData();
    const fileExtension = '.' + file.name.split('.').pop();
    const file_name: string = Math.random().toString(36).substring(7) + new Date().getTime() + fileExtension;
    formData.append('image', file, file_name);
    this.docNameList.push((file_name).toString());
    const url: string = (ApiConnection.FILE_SERVER_URL + '/' + this.selectedWork['_id'] + '/' + file_name);
    this.attachmentExtention = '';
    if (fileExtension === '.avi' || fileExtension === '.flv' || fileExtension === '.mpeg' || this.attachmentExtention == '.mp4' || this.attachmentExtention == '.mov') {
      this.attachmentExtention = fileExtension;
    }
    this._attachmentList.push(new Attachment(file.name, file_name,
      file_name, file.type, fileExtension, file.size, '', file_name));
  }

  public async uploadFiles(): Promise<boolean> {
    try {
      if (this.selectedFileList === undefined) {
        console.log('No file selected!');
        return false;
      }
      if (this.selectedFileList.length > 0 && this._attachmentList.length > 0) {
        this._uploadService.uploadFiles(ApiConnection.FILE_SERVER_URL + ApiConnection.FILE_VERSION + 'upload', this.selectedFileList,
          this.selectedWork['_id'], this._attachmentList)
          .subscribe(
            async (event) => {
              if (event.type === HttpEventType.UploadProgress) {
                const percentDone = Math.round(100 * event.loaded / event.total);
                this.progress = percentDone;
              } else if (event instanceof HttpResponse) {
                this.final = 100;
              }
            },
            (err) => {
              this.toaster.error('Error');
              return false;
            }, async () => {
              this.toaster.success(this.translateLabels['uploadSuccess']);
              // this.messageList.push(this.updatedMessage);
              // this._socketService.sendMessageEmitter(this.updatedMessage);
              this._workService.workSubject.next(null);
              return true;
            });
      }
      this.selectedFileList = [];
      this._attachmentList = [];
    } catch (ex) {  }
  }

  /* Eof Attachments */

  public setToday() {
    return this.translateLabels['today'];
  }

  /* Eof localization */

  public confirmDelete(id) {
    swalWithBootstrapButtons({
      title: this.translateLabels['rusure'],
      text: this.translateLabels['cantRevert'],
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translateLabels['yesDelete'],
      cancelButtonText: this.translateLabels['cancel'],
      reverseButtons: false
    }).then((result) => {
      if (result.value) {
        this.deleteMessage(id);
        this.toaster.success(this.translateLabels['messageDeleted']);
      } else if (
        // Read more about handling dismissals
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons(
          this.translateLabels['cancelled'],
          '',
          'error',
        );
      }
    });
  }

  public confirmAttachmentDelete(attachment_index: string, message: Message) {
    swalWithBootstrapButtons({
      title: this.translateLabels['rusure'],
      text: this.translateLabels['cantRevert'],
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translateLabels['yesDelete'],
      cancelButtonText: this.translateLabels['cancel'],
      reverseButtons: false
    }).then((result) => {
      if (result.value) {
        if (message.attachments.length === 1 && (message.text === '' || message.text === undefined)) {
          this.deleteMessage(message['_id']);
        }
        this.removeAttachment(attachment_index, message);
        this.toaster.success(this.translateLabels['attachmentDeleted']);
        this._workService.workSubject.next(null);
      } else if (
        // Read more about handling dismissals
        result.dismiss === swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons(
          this.translateLabels['cancelled'],
          '',
          'error'
        );
      }
    });
  }

  /* download option */

  public download(url: string, originalName: string) {
    if (url) {
      const filename = url.split('/')[url.split('/').length - 1];
      if (filename) {
        this._uploadService.downloadFile(filename, this.selectedWork['_id'])
          .subscribe(
            data => saveAs(data, originalName),
            error => console.error(error)
          );
      } else {
        console.log('Invalid file name!');
      }
    }
  }

  /* Datetime format conversions */
  getTimeDifference(currentMessage: Message, lang?: string) {
    const data = window.sessionStorage.getItem('50EE60E5468D8FC43984228303D24EE9');
    const user = JSON.parse(data);
    user.language = lang ? lang : user.language;
    try {
      let timeDif: string;
      if (currentMessage) {
        const message = (this.messageList).filter((x, i, a) => x['_id'] === currentMessage['_id'])[0];
        const startDate = new Date(this._chatService.getConvertedTime(message.creationDateTime));
        const endDate = new Date();
        const seconds = ((endDate.getTime() - startDate.getTime()) / 1000);

        if (seconds >= 60) {
          const toMins = (seconds / 60);
          if (user.language === 'en') {
            timeDif = parseInt(toMins.toString()) + ((toMins > 2) ? ' mins ago' : ' min ago');
          } else {
            timeDif = 'Hace ' + parseInt(toMins.toString()) + ' min.';
          }

          if (toMins >= 60) {
            const toHours = (toMins / 60);
            if (user.language === 'en') {
              timeDif = parseInt(toHours.toString()) + ((toHours > 2) ? ' hours ago' : ' hour ago');
            } else {
              timeDif = 'Hace ' + parseInt(toHours.toString()) + ' horas.';
            }

            if (toHours > 24) {
              if (toHours <= 48) {
                if (user.language === 'en') {
                  timeDif = 'yesterday at ' + this.changeTimeFormat(message.creationDateTime);
                } else {
                  timeDif = 'ayer a las ' + this.changeTimeFormat(message.creationDateTime);
                }
              } else {
                timeDif = this.changeTimeFormat(message.creationDateTime);
              }
            }
          }
        } else {
          if (seconds === 0) {
            if (user.language === 'en') {
              timeDif = '1 sec ago';
            } else {
              timeDif = 'Hace 1 segundo';
            }
          } else {
            if (seconds < 0) {
              if (user.language === 'en') {
                timeDif = '0 secs ago';
              } else {
                timeDif = 'Hace 0 segundo';
              }
            } else {
              if (user.language === 'en') {
                timeDif = parseInt(seconds.toString()) + ((seconds > 2) ? ' secs ago' : ' sec ago');
              } else {
                timeDif = 'Hace ' + parseInt(seconds.toString()) + ' segundo';
              }
            }
          }
        }
      }
      return timeDif;
    } catch (ex) {
      //console.log(ex + ' on mssg box');
    }
  }

  getchatCreateDateTimeDisplay(currentMessage: Message, lang?: string) {
    const data = window.sessionStorage.getItem('50EE60E5468D8FC43984228303D24EE9');
    const user = JSON.parse(data);
    user.language = lang ? lang : user.language;

    try {
      let timeDif: string;
      if (currentMessage) {
        const message = (this.messageList).filter((x, i, a) => x['_id'] === currentMessage['_id'])[0];
        const startDate = new Date(this._chatService.getConvertedTime(message.creationDateTime));
        const now = new Date(); // Current date and time
        const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1); // Yesterday's date

        // Check if the given date is today
        if (startDate.getFullYear() === now.getFullYear() && startDate.getMonth() === now.getMonth() && startDate.getDate() === now.getDate()) {
          timeDif = this.changeTimeFormat(message.creationDateTime); // Show only the time
        }

        // Check if the given date is yesterday
        else if (startDate.getFullYear() === yesterday.getFullYear()
          && startDate.getMonth() === yesterday.getMonth()
          && startDate.getDate() === yesterday.getDate()) {

          if (user.language === 'en') {
            timeDif = 'Yesterday ' + this.changeTimeFormat(message.creationDateTime);
          } else {
            timeDif = 'Ayer ' + this.changeTimeFormat(message.creationDateTime);
          }
        }
        else {
          timeDif = this._chatService.getConvertedDate(message.creationDateTime) + " " + this.changeTimeFormat(message.creationDateTime);
        }

      }
      return timeDif;
    } catch (ex) {
      //console.log(ex + ' on mssg box');
    }
  }

  public changeDateFormat(date: string) {
    if (date) {
      return new Date(this._chatService.getConvertedTime(date)).toLocaleDateString();
    }
  }

  public changeTimeFormat(date: string) {
    if (date) {
      const data = window.sessionStorage.getItem('50EE60E5468D8FC43984228303D24EE9');
      const user = JSON.parse(data);
      this.userLanguage = navigator.language;

      return new Date(this._chatService.getConvertedTime(date)).toLocaleTimeString(user.language === 'en' ? 'en-US' : 'es-ES');
    }
  }

  public getConvertedDate(dateTime: string) {
    const offset = new Date().getTimezoneOffset() * (-1);
    const serverDate = new Date(dateTime);
    const nd = new Date(serverDate.getTime() + (offset / 60)).toLocaleDateString();
    return nd;
  }

  public setPrevious() {
    if (this.isoldest !== 1) {
      this.latest = this.latest - 1;
      this.oldest = this.latest - 1;
      this._chatService.setLatest(this.latest);
      this._chatService.setOldest(this.oldest);
      this._chatService.setAndPublishMessage(this.selectedWork, this.latest, this.oldest);
    }
  }

  public setLatest() {
    if (this.islatest !== 1) {
      this.oldest = this.oldest + 1;
      this.latest = this.oldest + 1;
      this._chatService.setLatest(this.latest);
      this._chatService.setOldest(this.oldest);
      this._chatService.setAndPublishMessage(this.selectedWork, this.latest, this.oldest);
    }
  }

  public showLatestSeven() {
    this._chatService.setLatest(this.latest);
    this._chatService.setOldest(this.oldest);
    this._chatService.setAndPublishMessage(this.selectedWork, undefined, undefined);
  }

  public onOpenModal(message: Message): void {
    this.openedMessageId = message['_id'];
  }

  public async OnClickWorkItemBox(location: string): Promise<void> {

    if (location !== undefined && location !== '') {
      location = (location === 'c') ? 'l' : 'c';
      this.selectedWork.workItemLocation = location;
      this._socketService.updateWorkEmitter(this.selectedWork);

      const message: Message = await this.setNewMessageData();
      message.text = this.translateLabels['workItemLocationChanged'] + ((location === 'c') ?
        this.translateLabels['clinic'] : this.translateLabels['lab']);

      this._socketService.sendMessageEmitter(message);
      if (this._unSeenMessageList) {
        const list: Message[] = this.filterMessagesByWork(this._unSeenMessageList, this.selectedWork['_id']);
        this.updateUnSeenMessages(list);
      }
      this._socketService.getUnseenMessagesEmitter();
      await this._chatService.setAndPublishMessage(this.selectedWork, undefined, undefined);
      this._socketService.getWorksEmitter(this.user_id, this.user_type);
      this._socketService.getAttachmentsByWorkIdEmitter(this.selectedWork['_id']);

    } else { console.log('location not defined!'); }
  }

  public UpdateWork(work: Work): void {
    if (this.selectedWork !== undefined) {
      this._workService.updateWorkById(work['_id'], work).subscribe();
    } else {
      console.log('work is undefined!');
    }
  }

  /**
   * Fires when user remove an attachment before going to upload (right side attachment dropper)
   * Remove file name from doc name list
   * Remove file from selected file list and attachment list
   * @param item
   */
  onRemoveAttachment(item: string) {
    if (item) {
      if (this._attachmentList.length > 0 && this.selectedFileList.length > 0 && this.docNameList.length > 0) {
        const attachment = this._attachmentList.find(x => x.newName.toLowerCase() === item.toLowerCase());
        const attachmentIndex = this._attachmentList.findIndex(x => x.newName.toLowerCase() === item.toLowerCase());
        if (attachment) {
          this._attachmentList.splice(attachmentIndex, 1);
          this.docNameList.splice(this.docNameList.indexOf(item), 1);
        }
      }
    }
  }
}

export function Delay(ms: number): Promise<any> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Guid } from "guid-typescript";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Work } from "src/app/model/work";
import { ChatService } from "src/app/service/chat.service";
import * as moment from "moment";
import { ShowInvalidFormControls } from "src/app/service/validators";
import { ToastrService } from "ngx-toastr";
import { CommonUiService } from "src/app/service/common.ui.service";
import { LanguageService } from "src/app/service/language.service";
import { WorkService } from "src/app/service/work.service";
import { CommonFunctions } from "src/app/common/common-functions";
import { PiezasLower, PiezasTop } from "src/app/common/global";
import { FileUploader } from "ng2-file-upload";
import { ApiConnection } from "src/app/service/apiConnection";
import { UploadService } from "src/app/service/upload.service";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { Subscription } from "rxjs";
import { Attachment } from "src/app/model/attachment";
import { Message } from "src/app/model/message";
import { SocketService } from "src/app/service/sockets.service";

@Component({
  selector: "app-add-work",
  templateUrl: "./add-work.component.html",
  styleUrls: ["./add-work.component.scss"],
})
export class AddWorkComponent implements OnInit {
  public labList: any[] = [];
  public work: Work;
  isEdit = false;
  loggedUser: any;
  previousDoctorName = "";

  // formgroups
  workFormGroup: UntypedFormGroup;

  PIEZASTop = PiezasTop;
  PIEZASLower = PiezasLower;

  private selectedFileList: any[] = [];
  private _attachmentList: Attachment[] = [];
  public translateLabels: string[] = [];
  public subscriber: Subscription;

  public documentPath: string;
  public docNameList: string[] = [];
  public docNameListOriginal: string[] = [];
  public attachmentSize: number;
  public attachmentExtention: string;
  public loadingURL: string;
  public imgSrc: string;
  loading = false;
  public progress = 0;
  public final = 0;
  private timer: any;
  selectedWorkId: any;
  savedWork = new Work();

  public sent = "sent";
  public delivered = "delivered";
  public read = "read";

  public uploader: FileUploader = new FileUploader({
    url: ApiConnection.FILE_SERVER_URL + ApiConnection.FILE_VERSION,
    itemAlias: "logoPath",
  });

  constructor(
    public modal: NgbActiveModal,
    private _chatService: ChatService,
    private formBuilder: UntypedFormBuilder,
    private _uiLoaderService: NgxUiLoaderService,
    private _toastrService: ToastrService,
    public commonUiService: CommonUiService,
    private _languageService: LanguageService,
    private _workService: WorkService,
    private _uploadService: UploadService,
    private _socketService: SocketService
  ) {
    const data = window.sessionStorage.getItem(
      "50EE60E5468D8FC43984228303D24EE9"
    );
    if (data) {
      this.loggedUser = JSON.parse(data);
    }
  }

  ngOnInit() {
    this.docNameList = [];
    this.docNameListOriginal = [];
    this._attachmentList = [];
    this.selectedFileList = [];
    this.selectedWorkId = null;
    this.savedWork = new Work();

    this.getLabsByCenterGuid(this.loggedUser.guid);
    this.InitFormGroups();
    if (this.isEdit) {
      this.InitializeEditFormForGroup(this.work);
    }

    this.subscriber = this._chatService.translateLabel.subscribe(
      (translateLabel) => {
        this.translateLabels = translateLabel;
      }
    );
  }

  private InitFormGroups() {
    const guid = Guid.create();
    this.workFormGroup = this.formBuilder.group({
      clinicId: [],
      labId: ["", [Validators.required]],
      labName: ["", [Validators.required]],
      description: [""],
      patientName: ["", [Validators.required]],
      patientSurname: [""],
      patientHistoryNumber: [""],
      workColor: [""],
      pieces: ["", Validators.pattern("^([1-9]{2})((-|,)[1-9]{2})*$")],
      clinicObservation: [""],
      guid: [this.isEdit ? "" : guid],
      collaborator: [""],
      entryDate: [null],
      deliveryDate: [null],
      deliveryTime: ["", [Validators.minLength(4)]],
    });
    this.workFormGroup.controls.entryDate.setValue(this.GetCurrentDate());
  }

  private InitializeEditFormForGroup(work: Work) {
    this.workFormGroup.setValue({
      clinicId: work.clinicId ? work.clinicId : "",
      labId: work.labId ? work.labId : "",
      labName: work.labName ? work.labName : "",
      description: work.description ? work.description : "",
      patientName: work.patientName ? work.patientName : "",
      guid: work.guid ? work.guid : "",
      collaborator: work.collaboratorDescription
        ? work.collaboratorDescription
        : "",
      entryDate: work.entryDate
        ? this.commonUiService.ToNgbDate(new Date(work.entryDate))
        : null,
      deliveryDate: work.deliveryDate
        ? this.commonUiService.ToNgbDate(new Date(work.deliveryDate))
        : null,

      deliveryTime: work.deliveryTime ? work.deliveryTime : "",
      patientHistoryNumber: work.patientHistoryNumber,
      patientSurname: work.patientSurname ? work.patientSurname : "",
      pieces: work.pieces ? work.pieces : "",
      workColor: work.workColor ? work.workColor : "",
      clinicObservation: work.clinicObservation ? work.clinicObservation : "",
    });
    this.workFormGroup.controls.guid.disable();
    this.workFormGroup.controls.entryDate.disable();

    const userType = sessionStorage.getItem("1F4E6C2A587EAD3371E85EC3C08CFFCF");
    if (userType === "lab") {
      this.workFormGroup.controls.labName.disable();
    } else {
      this.workFormGroup.controls.labName.enable();
    }
  }

  GetCurrentDate() {
    return this.commonUiService.ConvertToPickerDateFormat(
      moment().format("YYYY-MM-DD")
    );
  }

  // get all connected active labs
  private getLabsByCenterGuid(centerGuid: string) {
    this._chatService.getLabsByCenterGuid(centerGuid).subscribe(
      (res) => {
        this.labList = res.data;
      },
      (err) => console.error(err),
      () => {}
    );
  }

  async onSelectLab(lab?: any) {
    try {
      if (lab) {
        this.workFormGroup.controls.labId.setValue(lab.centerId);
      } else {
        this.workFormGroup.controls.labId.setValue(null);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  async saveWork() {
    if (this.workFormGroup.invalid) {
      ShowInvalidFormControls(this.workFormGroup);
      return;
    } else {
      this._uiLoaderService.start();
      const work = this.workFormGroup.value;
      let workData = new Work();
      workData = work;
      if (workData.entryDate) {
        workData.entryDate = this.commonUiService
          .ChangeNgbDateToCustomFormat(workData.entryDate)
          .toString();
      }
      if (workData.deliveryDate) {
        workData.deliveryDate = this.commonUiService
          .ChangeNgbDateToCustomFormat(workData.deliveryDate)
          .toString();
      }
      if (workData.collaborator) {
        localStorage.setItem("doctor-name", workData.collaborator); // save latest previous doctor name in local storage
      }
      if (this.work._id && this.work._id.length > 0) {
        this._uiLoaderService.start();
        workData.entryDate = this.work.entryDate;
        workData._id = this.work._id;
        workData.state = this.work.state;
        workData.clinicId = this.work.clinicId;
        workData.guid = this.work.guid;
        this._chatService.updateWork(workData).subscribe(
          async (res) => {
            if (res) {
              res.labName = workData.labName;
              this._workService.workSubject.next(res);
              this._chatService.setSelectedWork(res);
              this._workService.workViewSubject.next(res);
              const successMsge = await this._languageService.getTranslation(
                "common.update_success"
              );
              this._toastrService.success(successMsge);
              this._uiLoaderService.stop();
              this.modal.close();
            }
          },
          async (err) => {
            console.log(err);
            const errorMsge = await this._languageService.getTranslation(
              "common.update_error"
            );
            this._toastrService.error(errorMsge);
            this._uiLoaderService.stop();
            this.modal.close();
          }
        );
      } else {
        this._uiLoaderService.start();
        workData.state = "pending";
        workData.guid = work.guid ? work.guid.value : "";
        workData.clinicId = this.loggedUser._id;
        const language = sessionStorage.getItem("currentLang");
        this._chatService.saveWork(language, workData).subscribe(
          async (res) => {
            if (res) {
              // TODO send attachements as messages
              let _data = res["data"];
              this.savedWork = _data;
              this.selectedWorkId = _data._id;
              this.sendAttachments(_data._id);
              res.labName = workData.labName;
              this._workService.workSubject.next(res);
              this._chatService.setSelectedWork(res);
              this._workService.workViewSubject.next(res);
              const successMsge = await this._languageService.getTranslation(
                "common.save_success"
              );
              this._toastrService.success(successMsge);
              this._uiLoaderService.stop();
              this.modal.close();
            }
          },
          async (err) => {
            console.log(err);
            const errorMsge = await this._languageService.getTranslation(
              "common.save_error"
            );
            this._toastrService.error(errorMsge);
            this._uiLoaderService.stop();
            this.modal.close();
          }
        );
      }
    }
  }

  onCloseModal() {
    this.modal.close();
    this._uiLoaderService.stop();
  }

  onFocus(focusIn: boolean) {
    this.previousDoctorName = focusIn
      ? localStorage.getItem("doctor-name")
      : "";
  }

  onSelectDoctor() {
    this.workFormGroup.controls.collaborator.setValue(
      this.previousDoctorName ? this.previousDoctorName : ""
    );
    this.previousDoctorName = "";
  }

  OnTypePiezasNumber() {
    const value = CommonFunctions.ValidatePiezasNumber(
      this.workFormGroup.controls.pieces.value
    );
    if (value != "invalidValue" && value != "invalidRange") {
      this.workFormGroup.controls.pieces.setValue(value.toString());
    }
  }

  OnTypeColorPiezasNumber(control: any) {
    try {
      if (control.value == "") return;
      const value = CommonFunctions.ValidatePiezasNumber(control.value);
      if (value != "invalidValue" && value != "invalidRange") {
        control.setValue(value.toString());
      } else if (value == "invalidValue") {
        control.setErrors({ invalidValue: true });
      } else if (value == "invalidRange") {
        control.setErrors({ invalidRange: true });
      }
    } catch (error) {}
  }

  /* Attachments */

  // Remove a document from the list
  public removeDocument(index: number) {
    this.docNameList.splice(index, 1);
    this._attachmentList.splice(index, 1);
    this.docNameListOriginal.splice(index, 1);
  }

  // add attached file to array
  public onFileChange(event) {
    let toupload = true;

    this.selectedFileList.forEach((element) => {
      if (event.target.files[0] && element[0]) {
        if (element[0].name === event.target.files[0].name) {
          toupload = false;
          this._toastrService.error(this.translateLabels["alreadyAdded"]);
        }
      }
    });
    if (toupload && event.target.files.length > 0) {
      this.selectedFileList.push(event.target.files);
      this.showDocList(event);
    }
  }

  clickFileInput(event) {
    event.target.value = "";
  }

  // show attached file names
  public showDocList(e) {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    for (let i = 0; i < target.files.length; i++) {
      this.upload(target.files[i]);
    }
  }

  // show attached file names
  public upload(file: File) {
    const formData: FormData = new FormData();
    const fileExtension = "." + file.name.split(".").pop();
    const originalFileName = file.name;
    const file_name: string =
      Math.random().toString(36).substring(7) +
      new Date().getTime() +
      fileExtension;
    formData.append("image", file, file_name);
    this.docNameList.push(file_name.toString());
    this.docNameListOriginal.push(originalFileName.toString());

    this.attachmentExtention = "";
    if (
      fileExtension === ".avi" ||
      fileExtension === ".flv" ||
      fileExtension === ".mpeg" ||
      this.attachmentExtention == ".mp4" ||
      this.attachmentExtention == ".mov"
    ) {
      this.attachmentExtention = fileExtension;
    }
    this._attachmentList.push(
      new Attachment(
        file.name,
        file_name,
        file_name,
        file.type,
        fileExtension,
        file.size,
        "",
        file_name
      )
    );
  }

  public async uploadFiles(workId: any): Promise<boolean> {
    try {
      if (this.selectedFileList === undefined) {
        console.log("No file selected!");
        return false;
      }
      if (this.selectedFileList.length > 0 && this._attachmentList.length > 0) {
        this._uploadService
          .uploadFiles(
            ApiConnection.FILE_SERVER_URL +
              ApiConnection.FILE_VERSION +
              "upload",
            this.selectedFileList,
            workId,
            this._attachmentList
          )
          .subscribe(
            async (event) => {
              if (event.type === HttpEventType.UploadProgress) {
                const percentDone = Math.round(
                  (100 * event.loaded) / event.total
                );
                this.progress = percentDone;
              } else if (event instanceof HttpResponse) {
                this.final = 100;
              }
            },
            (err) => {
              this._toastrService.error("Error");
              return false;
            },
            async () => {
              this._toastrService.success(
                this.translateLabels["uploadSuccess"]
              );
              return true;
            }
          );
      }
      this.selectedFileList = [];
      this._attachmentList = [];
    } catch (ex) {}
  }

  async sendAttachments(workId: any): Promise<void> {
    if (
      this.selectedFileList === undefined ||
      this.selectedFileList.length === 0
    ) {
      return;
    }
    try {
      const message: Message = await this.setNewMessageData();

      if (!this.uploadFiles(workId)) {
        message.attachments = null;
        return;
      } else {
        this._socketService.sendMessageEmitter(message);
      }
    } catch (ex) {}
  }

  async setNewMessageData(): Promise<Message> {
    let message = new Message();
    message.attachments = this._attachmentList;
    message.workId = this.selectedWorkId;

    if (message !== undefined) {
      message.readDateTime = "";
      message.deliveredDateTime = "";
      message.state = this.sent;
      message.text = "";

      message.owner = "clinic";
      message.senderId = this.savedWork.clinicId ? this.savedWork.clinicId : "";
      message.recieverId = this.savedWork.labId ? this.savedWork.labId : "";
    }

    if (this.loggedUser) {
      message.loggedUserName = this.loggedUser.name;
      message.loggedUserCode = this.loggedUser.code;
    }
    return message;
  }

  /* Eof Attachments */
}
